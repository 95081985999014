<template>
  <div class="error-page">
    <div class="contain">
      <p><i class="el-icon-warning-outline"></i> 您已被强制退出考试</p>
      <el-button size="mini" @click="back">回到首页</el-button>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    back() {
      this.$router.push({
        path: "/home",
      });
    },
  },
};
</script>

<style lang="less" scope>
.error-page {
  background: #fff;
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 20px;
  line-height: 50px;
  .contain {
    margin-top: 20%;
  }
}
</style>